<template>
  <div id="label">
    <a-page-header
      title="标签管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />

    <a-form layout="inline" style="display: flex">
      <a-form-item label="类型: ">
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          style="width: 200px"
          placeholder="请选择"
          v-model="type.options"
          @change="handleChange"
        >
          <a-select-option v-for="item in filteredOptions" :key="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <div class="middle">
      <a-button
        type="primary"
        @click="showModal"
        v-if="$checkButtonPermissions($buttonPermissionsConfig.basicInformation.tagManage.add)"
      >
        <a-icon type="plus" />添加
      </a-button>
      <a-table
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        style="margin-top: 15px"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        bordered
      >
        <template slot="action" v-if="tableMode" slot-scope="text, record">
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="modify(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.basicInformation.tagManage.edit)"
            >编辑</a
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="deleteList(record.labelId)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.basicInformation.tagManage.delete)"
            >删除</a
          >
        </template>
      </a-table>
    </div>
    <div class="fotter">
      <a-pagination
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="['20', '50', '100']"
        :defaultCurrent="pageNum"
        :defaultPageSize="pageSize"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        :current="pageNum"
        :pageSize="pageSize"
        :showSizeChanger="true"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <div class="modal">
      <div>
        <a-modal
          v-if="visible"
          v-model="visible"
          :keyboard="false"
          :title="title"
          :closable="false"
          :maskClosable="false"
          on-ok="onSubmit"
        >
          <template slot="footer">
            <a-button key="back" @click="handleCancel"> 取消 </a-button>

            <a-button key="submit" type="primary" @click="onSubmit"> 确认保存 </a-button>
          </template>
          <div>
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
              <a-form-model-item
                ref="name"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 18 }"
                label="标签名称"
                prop="labelName"
              >
                <a-input
                  v-model.trim="form.labelName"
                  placeholder="请输入标签名称"
                  :maxLength="12"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur();
                    }
                  "
                />
                <div>同类型标签名不可重复</div>
              </a-form-model-item>
              <a-form-model-item
                style="margin-top: 10px"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 18 }"
                label="类型"
                prop="labelType"
              >
                <a-radio-group v-model="form.labelType">
                  <a-radio value="1"> 报告 </a-radio>
                  <a-radio value="0"> 用户 </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      pageSize: 20,
      pageNum: 1,
      tableMode: true, //表格编辑出现方块问题
      visible: false, //遮罩层
      title: "",
      isActive: false, //标识符 =>新增/修改
      selectedItems: [],
      type: { options: ["全部"] },
      filteredOptions: [
        {
          value: ["all"],
          label: "全部",
        },
        {
          value: ["1"],
          label: "报告",
        },
        {
          value: ["0"],
          label: "用户",
        },
      ],
      form: {
        labelName: "",
        labelType: "1",
      },
      selectedItemsIsactive: [], //=>防止输入空有值未检索 分页会带检索条件
      rules: {
        labelName: [
          { required: true, message: "标签名称不能为空", trigger: "blur" },
          { min: 1, max: 12, message: "不超过12个字符", trigger: "blur" },
        ],
        labelType: [{ required: true, message: "请选择标签类型", trigger: "change" }],
      },
      columns: [
        {
          title: "ID",
          dataIndex: "labelNumber",
        },
        {
          title: "类型",
          dataIndex: "labelType",
        },
        {
          title: "标签名称",
          dataIndex: "labelName",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreate",
        },
      ],
      // 操作集合
      actions: [
        this.$buttonPermissionsConfig.basicInformation.tagManage.edit,
        this.$buttonPermissionsConfig.basicInformation.tagManage.delete,
      ],
    };
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction());
    this.labelManagement({ pageNum: this.pageNum, pageSize: this.pageSize });
  },
  mounted() {},
  computed: {
    ...mapState({
      dataList: (state) => state.label.dataArr,
      total: (state) => state.label.total,
    }),
  },
  methods: {
    ...mapActions({
      labelManagement: "label/labelManagement",
      createLabel: "label/createLabel",
      modifyLabel: "label/modifyLabel",
      deleteLabel: "label/deleteLabel",
    }),
    //模糊查询
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    //编辑
    modify(arg) {
      this.isActive = false;
      this.visible = true;
      this.title = "编辑标签";
      let argShadow = JSON.parse(JSON.stringify(arg));
      argShadow.labelType = argShadow.labelType == "用户标签" ? "0" : "1";
      this.form = argShadow;
    },
    //添加
    showModal() {
      this.title = "添加标签";
      this.visible = true;
      this.isActive = true;
    },
    //删除
    deleteList(id) {
      console.log(id);
      this.$confirm({
        title: "确定要删除吗?",
        content: "删除后不可恢复",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.deleteLabel({
            labelId: id,
            pageNum: 1,
            pageSize: this.pageSize,
            cb: () => {
              this.success("删除成功");
            },
            cbError: (cb) => {
              this.warning(cb);
            },
          });
        },
        onCancel: () => {
          // this.warning("已取消");
          return false;
        },
      });
    },
    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },
    //新增/编辑 提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isActive) {
            this.createLabel({
              form: this.form,
              pageNum: 1,
              pageSize: this.pageSize,
              cb: (cb) => {
                this.success(cb);
                this.visible = false;
                this.form = {};
              },
              cbError: (cb) => {
                this.warning(cb);
                this.visible = true;
              },
            });
          } else {
            this.modifyLabel({
              form: this.form,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              cb: (cb) => {
                this.success(cb);
                this.visible = false;
                this.form = {};
              },
              cbError: (cb) => {
                this.warning(cb);
                this.visible = true;
              },
            });
          }
          this.tableMode = false;
          setTimeout(() => {
            this.tableMode = true;
          }, 1);
        } else {
          return false;
        }
      });
    },
    //取消
    handleCancel() {
      this.tableMode = false; //解决表格编辑 方块的问题。
      setTimeout(() => {
        this.tableMode = true;
      }, 1);
      this.visible = false;
      this.form = {};
    },
    //查询
    search() {
      this.selectedItemsIsactive = this.selectedItems;
      this.pageNum = 1;
      if (this.selectedItemsIsactive[0] == "all") {
        this.labelManagement({ pageNum: this.pageNum, pageSize: this.pageSize });
      } else {
        this.labelManagement({ pageNum: this.pageNum, pageSize: this.pageSize, typeList: this.selectedItemsIsactive });
      }
    },
    //重置
    resetSearchForm() {
      this.type = { options: ["全部"] };
      this.selectedItems = [];
      this.selectedItemsIsactive = [];
      this.pageNum = 1;
      this.pageSize = 20;
      this.labelManagement({ pageNum: 1, pageSize: 20 });
    },
    //下拉菜单
    handleChange(selectedItems) {
      console.log(selectedItems);
      if (selectedItems == "全部") {
        this.selectedItems = ["all"];
      } else if (selectedItems == "用户") {
        this.selectedItems = [0];
      } else {
        this.selectedItems = [1];
      }
    },
    //分页
    onShowSizeChange(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.labelManagement({ typeList: this.selectedItemsIsactive, pageNum, pageSize });
    },
    onChangePage(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.labelManagement({ typeList: this.selectedItemsIsactive, pageNum, pageSize });
    },
  },
};
</script>

<style scoped>
.middle {
  margin-top: 15px;
}

.fotter {
  float: right;
  padding: 20px 0;
}
::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}

.btnClass {
  margin-left: 10px;
}
</style>
